import Loadable from 'react-loadable'
import Loader from './components/Loader'

const allRoles =  ['admin', 'satuan-pendidikan', 'dinas-kabkota', 'dinas-provinsi', 'pengawas-pusat', 'pengawas-provinsi', 'pengawas-kabkota', 'mitra-siplah'];

export default [
  {
    path: '/',
    exact: true,
    allowedRoles: allRoles,
    component: Loadable({
      loader: () => import('./pages/welcome'),
      loading: Loader,
    }),
  },
  {
    path: '/dashboard',
    exact: true,
    allowedRoles: ['admin', 'dinas-kabkota', 'dinas-provinsi', 'pengawas-pusat', 'pengawas-provinsi', 'pengawas-kabkota', 'mitra-siplah', 'satuan-pendidikan'],
    component: Loadable({
      loader: () => import('./pages/dashboards'),
      loading: Loader,
    }),
  },
  {
    path: '/sla-compliance',
    exact: true,
    allowedRoles: ['admin', 'mitra-siplah'],
    component: Loadable({
      loader: () => import('./pages/slaCompliance'),
      loading: Loader,
    }),
  },
  {
    path: '/approval-permission',
    exact: true,
    allowedRoles: ['admin'],
    component: Loadable({
      loader: () => import('./pages/users'),
      loading: Loader,
    }),
  },
  {
    path: '/approval-permission/detail/:id',
    exact: true,
    allowedRoles: ['admin'],
    component: Loadable({
      loader: () => import('./pages/users/approvalDetail'),
      loading: Loader,
    }),
  },
  {
    path: '/approval-permission/create',
    exact: true,
    allowedRoles: ['admin'],
    component: Loadable({
      loader: () => import('./pages/users/Create'),
      loading: Loader,
    }),
  },
  {
    path: '/approval-permission/edit/:id',
    exact: true,
    allowedRoles: ['admin'],
    component: Loadable({
      loader: () => import('./pages/users/editApprovalUser'),
      loading: Loader,
    }),
  },
  {
    path: '/transaction-list',
    exact: true,
    allowedRoles: ['admin', 'dinas-kabkota', 'dinas-provinsi', 'pengawas-pusat', 'pengawas-provinsi', 'pengawas-kabkota', 'mitra-siplah'],
    component: Loadable({
      loader: () => import('./pages/transaction'),
      loading: Loader,
    }),
  },
  {
    path: '/transaction-all',
    exact: true,
    allowedRoles: allRoles,
    component: Loadable({
      loader: () => import('./pages/problematicTransaction'),
      loading: Loader,
    }),
  },
  {
    path: '/dashboard/district/:id',
    exact: true,
    allowedRoles: ['admin', 'dinas-kabkota', 'dinas-provinsi', 'pengawas-pusat', 'pengawas-provinsi', 'pengawas-kabkota', 'mitra-siplah'],
    component: Loadable({
      loader: () => import('./pages/dashboards/district'),
      loading: Loader,
    }),
  },
  {
    path: '/dashboard/school/:id/city=:name',
    exact: true,
    allowedRoles: ['admin', 'dinas-kabkota', 'dinas-provinsi', 'pengawas-pusat', 'pengawas-provinsi', 'pengawas-kabkota', 'mitra-siplah'],
    component: Loadable({
      loader: () => import('./pages/dashboards/city'),
      loading: Loader,
    }),
  },
  // {
  //   path: '/transaction/district/:id',
  //   exact: true,
  //   component: Loadable({
  //     loader: () => import('./pages/district'),
  //     loading: Loader,
  //   }),
  // },
  // {
  //   path: '/transaction/school/:id/city=:name',
  //   exact: true,
  //   component: Loadable({
  //     loader: () => import('./pages/city'),
  //     loading: Loader,
  //   }),
  // },
  {
    path: '/school/:id',
    exact: true,
    allowedRoles: allRoles,
    component: Loadable({
      loader: () => import('./pages/profileSchool'),
      loading: Loader,
    }),
  },
  {
    path: '/school/detail-transaction/:id',
    exact: true,
    allowedRoles: allRoles,
    component: Loadable({
      loader: () => import('./pages/profileSchool/Transaction'),
      loading: Loader,
    }),
  },
  {
    path: '/source-transaction',
    exact: true,
    allowedRoles: ['admin', 'dinas-kabkota', 'dinas-provinsi', 'pengawas-pusat', 'pengawas-provinsi', 'pengawas-kabkota', 'mitra-siplah'],
    component: Loadable({
      loader: () => import('./pages/schoolList'),
      loading: Loader,
    }),
  },
  {
    path: '/source-transaction/by-city/:id/:src',
    exact: true,
    allowedRoles: ['admin', 'dinas-kabkota', 'dinas-provinsi', 'pengawas-pusat', 'pengawas-provinsi', 'pengawas-kabkota', 'mitra-siplah'],
    component: Loadable({
      loader: () => import('./pages/schoolList/byKab'),
      loading: Loader,
    }),
  },
  {
    path: '/source-transaction/by-school/:id/:src',
    exact: true,
    allowedRoles: ['admin', 'dinas-kabkota', 'dinas-provinsi', 'pengawas-pusat', 'pengawas-provinsi', 'pengawas-kabkota', 'mitra-siplah'],
    component: Loadable({
      loader: () => import('./pages/schoolList/bySchool'),
      loading: Loader,
    }),
  },
  {
    path: '/goods-list',
    exact: true,
    allowedRoles: allRoles,
    component: Loadable({
      loader: () => import('./pages/goodsList'),
      loading: Loader,
    }),
  },
  {
    path: '/goods-list/district/:id',
    exact: true,
    allowedRoles: allRoles,
    component: Loadable({
      loader: () => import('./pages/goodsList/district'),
      loading: Loader,
    }),
  },
  {
    path: '/goods-list/city/:id',
    exact: true,
    allowedRoles: allRoles,
    component: Loadable({
      loader: () => import('./pages/goodsList/city'),
      loading: Loader,
    }),
  },
  {
    path: '/store-list',
    exact: true,
    allowedRoles: allRoles,
    component: Loadable({
      loader: () => import('./pages/storeList'),
      loading: Loader,
    }),
  },
  {
    path: '/store-list/district/:id',
    exact: true,
    allowedRoles: allRoles,
    component: Loadable({
      loader: () => import('./pages/storeList/district'),
      loading: Loader,
    }),
  },
  {
    path: '/store-list/city/:id',
    exact: true,
    allowedRoles: allRoles,
    component: Loadable({
      loader: () => import('./pages/storeList/city'),
      loading: Loader,
    }),
  },
  {
    path: '/change-password',
    exact: true,
    allowedRoles: allRoles,
    component: Loadable({
      loader: () => import('./pages/changePassword'),
      loading: Loader,
    }),
  },
  {
    path: '/user-list',
    exact: true,
    allowedRoles: ['admin'],
    component: Loadable({
      loader: () => import('./pages/users/List'),
      loading: Loader,
    }),
  },
  {
    path: '/user-list/detail/:id',
    exact: true,
    allowedRoles: ['admin'],
    component: Loadable({
      loader: () => import('./pages/users/detail'),
      loading: Loader,
    }),
  },
  {
    path: '/user-list/edit/:id',
    exact: true,
    allowedRoles: ['admin'],
    component: Loadable({
      loader: () => import('./pages/users/editUser'),
      loading: Loader,
    }),
  },
  {
    path: '/sso-client',
    exact: true,
    allowedRoles: ['admin'],
    component: Loadable({
      loader: () => import('./pages/ssoClient'),
      loading: Loader,
    }),
  },
  {
    path: '/sso-client/Form',
    exact: true,
    allowedRoles: ['admin'],
    component: Loadable({
      loader: () => import('./pages/ssoClient/Form'),
      loading: Loader,
    }),
  },
  {
    path: '/sso-client/:id/edit',
    exact: true,
    allowedRoles: ['admin'],
    component: Loadable({
      loader: () => import('./pages/ssoClient/Form'),
      loading: Loader,
    }),
  },
  {
    path: '/pdn-umkm-list',
    exact: true,
    allowedRoles: allRoles,
    component: Loadable({
      loader: () => import('./pages/pdnUmkm'),
      loading: Loader,
    }),
  },
  {
    path: '/data-gap',
    exact: true,
    allowedRoles: ['admin','mitra-siplah'],
    component: Loadable({
      loader: () => import('./pages/dataGap'),
      loading: Loader,
    }),
  },
  {
    path: '/general-data-quality',
    exact: true,
    allowedRoles: ['admin','mitra-siplah'],
    component: Loadable({
      loader: () => import('./pages/GeneralDataQuality'),
      loading: Loader,
    }),
  },
  {
    path: '/detail-data-quality',
    exact: true,
    allowedRoles: ['admin','mitra-siplah'],
    component: Loadable({
      loader: () => import('./pages/DetailDataQuality'),
      loading: Loader,
    }),
  },
  {
    path: '/goods-transaction',
    exact: true,
    allowedRoles: ['satuan-pendidikan'],
    component: Loadable({
      loader: () => import('./pages/goodsTransaction'),
      loading: Loader,
    }),
  },
  {
    path: '/merchants-transaction',
    exact: true,
    allowedRoles: ['satuan-pendidikan'],
    component: Loadable({
      loader: () => import('./pages/merchantsTransaction'),
      loading: Loader,
    }),
  },
  {
    path: '/monitoring-keterisian-bku',
    exact: true,
    allowedRoles: ['admin', 'mitra-siplah'],
    component: Loadable({
      loader: () => import('./pages/dqmIntegration/index'),
      loading: Loader,
    }),
  },
  {
    path: '/daftar-transaksi-integrasi-bku',
    exact: true,
    allowedRoles: ['admin', 'mitra-siplah'],
    component: Loadable({
      loader: () => import('./pages/dqmIntegration/transaction-list'),
      loading: Loader,
    }),
  },
]
