import React from "react"
import Chart from "react-apexcharts"
import { Spin } from 'antd'
import Helper from "../../utils/Helper"

class LineChart extends React.Component {

  render(){
  	const { data, isLoading } = this.props
    let x
    let series =[]
    // let label =[]
    if (data) {
      data.forEach(item => {
        x = {
          name: item.name || '-',
          data: item.values || 0
        }
        series.push(x)
      })
    }
    return(                  
			<div>
        {isLoading ?
          <div className="text-center">
            <Spin />
          </div>
        :
          <Chart
            options={{
              chart: {
                height: 350,
                type: 'line',
                dropShadow: {
                  enabled: true,
                  color: '#000',
                  top: 18,
                  left: 7,
                  blur: 10,
                  opacity: 0.2
                },
                toolbar: {
                  show: false
                }
              },
              
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: 'smooth'
              },
              grid: {
                borderColor: '#e7e7e7',
                row: {
                  colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                  opacity: 0.5
                },
              },
              markers: {
                size: 1
              },
              xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
                title: {
                  text: 'Month'
                }
              },
              yaxis: {
                title: {
                  text: 'Total'
                },
                labels: {
                  show: true,
                  formatter: function (val) {
                    return Helper.convertSeparator(val)
                  }
                }
              },
              legend: {
                position: 'top',
                horizontalAlign: 'right',
                floating: true,
                offsetY: -25,
                offsetX: -5
              }
            }}
            series={series}
            type="line"
            height={500}
          />
        }
      </div>
    )
  }
}

export default LineChart
