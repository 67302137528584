import React from 'react'
import isEqual from 'lodash/isEqual';
import {
  Row,
  Col,
  Card,
  Select,
  Button,
  // Checkbox
} from 'antd'

class DashboardFilter extends React.Component{
  state = {
    provinceList: [],
    educationList: [],
    cityList: [],
    fundList: [],
    selectedYear: null,
    selectedEducation: null,
    selectedSourceFund: null,
    selectedTransactions: undefined,
    selectedPayments: undefined,
    onlyHangingStatus: false,
    notCompleteComplaintStatus: false,
    yearList: {
      data: [],
      isFetching: true
    },
    transactionList: {
      data: [],
      isFetching: true
    },
    paymentList: {
      data: [],
      isFetching: true
    },
    selectedProvince: null,
    selectedCity: null,
    cityDisable: true
  }

  componentDidUpdate(prevProps, prevState){
    const { yearData, paymentData, transactionData } = this.props
    const { yearList, paymentList, transactionList } = this.state

    if (!isEqual(prevProps.yearData?.data, yearList?.data)) {
      this.setState({
        yearList: {
          data: yearData?.data,
          isFetching: yearData?.isFetching
        }
      }) 
    }

    if (!isEqual(prevProps.transactionData?.data, transactionList?.data)) {
      this.setState({
        transactionList: {
          data: transactionData?.data,
          isFetching: transactionData?.isFetching
        }
      }) 
    }

    if(!isEqual(prevProps.paymentData?.data, paymentList?.data)){
      this.setState({
        paymentList: {
          data: paymentData?.data,
          isFetching: paymentData?.isFetching
        }
      }) 
    }
  }

  handleChangeValProvince = (val) => {
    const {auth} = this.props

    this.setState({
      selectedProvince: val,
      selectedCity: null,
      cityDisable: val === undefined ? true : false,
      cityList: auth.allowed_cities.filter((d)=> d.kd_prop === val)
    })

    this.props.handleChangeProvinceFilter(val)
  }

  handleChangeValCity = (val) => {
    this.setState({
      selectedCity: val
    })

    this.props.handleChangeCityFilter(val)
  }

  handleChangeYear = (val) => {
    this.setState({
      selectedYear: val
    })

    this.props.handleChangeYearFilter(val)
  }

  handleChangeEducation = (val) => {
    this.setState({
      selectedEducation: val
    })

    this.props.handleChangeEducationFilter(val)
  }

  handleChangeSumberDana = (val) => {
    this.setState({
      selectedSourceFund: val
    })

    this.props.handleChangeFundFilter(val)
  }

  handleChangeTransactions = (val) => {
    this.setState({
      selectedTransactions: val
    })

    this.props.handleChangeTxStatusFilter(val)
  }

  handleChangePayments = (val) => {
    this.setState({
      selectedPayments: val
    })

    this.props.handleChangePaymentStatusFilter(val)
  }

  handleChangeNotComplete = (e) => {
    this.setState({
      notCompleteComplaintStatus: e.target.checked
    })

    this.props.onChangeNotCompleteComplain(e.target.checked)
  }

  handleChangeOnlyHanging = (e) => {
    this.setState({
      onlyHangingStatus: e.target.checked
    })

    this.props.onChangeOnlyHanging(e.target.checked)
  }

  render(){
    const {
      yearValue, educationValue, sourceFundValue,
      transactionValue, paymentValue, cityValue,
      provinceValue,
      // isOnlyHanging, isNotCompleteComplaint,
      isHaveProvCity, userRole
    } = this.props

    const {
      selectedProvince, selectedCity, cityDisable,
      cityList, yearList, transactionList, paymentList,
      selectedYear, selectedEducation, selectedSourceFund,
      selectedTransactions, selectedPayments,
      // onlyHangingStatus, notCompleteComplaintStatus
    } = this.state

    if (cityValue) {
      this.setState({
        cityDisable: false
      })
    }

    return(
      <Card className="mb-3">
        <Row gutter={[24,24]} className="mb-4">
          {isHaveProvCity && userRole?.role_name !== 'satuan-pendidikan' &&
            <Col xs={24} sm={6}>
              <Select
                value={provinceValue || selectedProvince}
                allowClear
                showSearch
                style={{ width: '100%' }}
                placeholder="Pilih provinsi"
                onChange={this.handleChangeValProvince}
                loading={false}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {(this.props.provinceList || []).map(item => <Select.Option key={item.kd_prop} value={item.kd_prop}>{item.name}</Select.Option>)}
              </Select>
            </Col>
          }
          <Col xs={24} sm={5}>
            <Select
              allowClear
              showSearch
              value={educationValue || selectedEducation}
              style={{ width: '100%' }}
              placeholder="Pilih jenjang pendidikan"
              onChange={this.handleChangeEducation}
              loading={false}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {(this.props.educationList || []).map((item,index) => <Select.Option key={index} value={item.stage}>{item.stage}</Select.Option>)}
            </Select>
          </Col>
          <Col xs={24} sm={5}>
            <Select
              mode="multiple"
              maxTagCount={1}
              value={transactionValue || selectedTransactions}
              allowClear
              showSearch
              style={{ width: '100%' }}
              placeholder="Pilih Status Transaksi"
              onChange={this.handleChangeTransactions}
              loading={transactionList?.isFetching}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {(transactionList?.data || []).map((item, index) => <Select.Option key={index} value={item.kd_status_transaksi}>{item.status_label}</Select.Option>)}
            </Select>
          </Col>
          <Col xs={24} sm={5}>
            <Select
              mode="multiple"
              maxTagCount={1}
              allowClear
              showSearch
              value={paymentValue || selectedPayments}
              style={{ width: '100%' }}
              placeholder="Pilih Status Pembayaran"
              onChange={this.handleChangePayments}
              loading={paymentList?.isFetching}
              disable={false}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {(paymentList?.data || []).map((item, index) => <Select.Option key={index} value={item.kd_status_pembayaran}>{item.status_label}</Select.Option>)}
            </Select>
          </Col>
          <Col xs={24} sm={3}>
            <Button type="primary" className="m-r-10" onClick={this.props.handleSubmitFilter}>
              Filter/Cari
            </Button>
          </Col>
        </Row>
        <Row gutter={[24,24]}>
          {isHaveProvCity && userRole?.role_name !== 'satuan-pendidikan' &&
            <Col xs={24} sm={6}>
              <Select
                value={cityValue || selectedCity}
                allowClear
                showSearch
                style={{ width: '100%' }}
                placeholder="Pilih kabupaten/kota"
                onChange={this.handleChangeValCity}
                disabled={cityDisable}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {(cityList || []).map(item => <Select.Option key={item.kd_kab_kota} value={item.kd_kab_kota}>{item.name}</Select.Option>)}
              </Select>
            </Col>
          }
          <Col xs={24} sm={5}>
            <Select
              placeholder="Pilih Sumber Dana"
              value={sourceFundValue || selectedSourceFund}
              onChange={this.handleChangeSumberDana}
              allowClear
              showSearch
              style={{ width: '100%' }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {(this.props.fundList || []).map((item) => <Select.Option key={item.kd_sumber_dana} value={item.kd_sumber_dana}>{item.fund_source_name}</Select.Option>)}
            </Select>
            <br/><br/>
            <Select
              placeholder="Pilih Tahun"
              onChange={this.handleChangeYear}
              allowClear
              value={selectedYear || yearValue}
              loading={yearList?.isFetching}
              showSearch
              style={{ width: '100%' }}
            >
              {(yearList?.data || []).map((item) => <Select.Option key={item.year} value={item.year}>{item.year}</Select.Option>)}
            </Select>
          </Col>
          {/* <Col xs={24} sm={10}>
            <Row>
              <Checkbox
                checked={isNotCompleteComplaint || notCompleteComplaintStatus}
                onChange={this.handleChangeNotComplete}
              >
                Pesanan yang terdapat komplain belum selesai
              </Checkbox>
            </Row>
            <Row>
              <Checkbox
                checked={isOnlyHanging || onlyHangingStatus}
                onChange={this.handleChangeOnlyHanging}
              >
                Pesanan yang telah melebihi 20 hari sejak dibuat
              </Checkbox>
            </Row>
          </Col> */}
        </Row>
      </Card>
    )
  }
}

export default DashboardFilter