import React from 'react';
import { Form, Select, Radio, ConfigProvider } from 'antd';
import { connect } from 'react-redux';
import { fetchProvince, fetchEducation, fetchFunds } from '../../actions/AdditionalForm';
import CustomizeNotFound from '../CustomizeNotFound';
import { isArray } from 'lodash';

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class DinasProvinsi extends React.Component {
  state = {
    provinceList: [],
    onLoadProvince: true,
    onLoadEducation: true,
    educationList: [],
    fundList: [],
    onLoadFunds: true,
    jenjangTypeValue: false,
    sumberDanaTypeValue: false,
    isFinishFetch: true
  }


  componentDidMount(){
    this.props.fetchProvince(this.props.auth.id_token || process.env.REACT_APP_API_KEY, 'limit=34')
    .then((result) => {
      this.setState({
        onLoadProvince: false,
        provinceList: result.items
      })
    }).catch((error) => {
      this.setState({
        onLoadProvince: false,
        provinceList: []
      })
    })

    this.props.fetchEducation(this.props.auth.id_token || process.env.REACT_APP_API_KEY)
    .then((result) => {
      this.setState({
        onLoadEducation: false,
        educationList: result.items
      })
    }).catch((error) => {
      this.setState({
        onLoadEducation: false,
        educationList: []
      })
    })

    this.props.fetchFunds(this.props.auth.id_token || process.env.REACT_APP_API_KEY)
    .then((result) => {
      this.setState({
        onLoadFunds: false,
        fundList: result.items
      })
    }).catch((error) => {
      this.setState({
        onLoadFunds: false,
        fundList: []
      })
    })
  }

  componentDidUpdate(prevProps, prevState){
    const { isEdit } = this.props
    const { isFinishFetch } = this.state

    if (isFinishFetch) {
      if (!this.state.onLoadProvince && !this.state.onLoadEducation && !this.state.onLoadFunds) {
        this.setState({
          isFinishFetch: false
        })
        if (isEdit) {
          this.handleEditForm() 
        }
      }
    }
  }

  handleEditForm = () => {
    const { isEdit, editValue } = this.props
    setTimeout(() => {
      this.handleChangeProvince((isArray(editValue?.province) ? editValue?.province[0] : editValue?.province), isEdit)

      if (editValue?.education === true) {
        this.onChangeJenjangType(true)
      } else {
        this.handleChangeEducation(editValue?.education)
      }

      if (editValue?.fund === true) {
        this.onChangeSumberDanaType(true)
      } else {
        this.handleChangeSource(editValue?.fund)
      }
    }, 500)
  }

  handleChangeProvince = (value, isEdit) => {
    let newProvinceVal
    if (isEdit === true) {
      newProvinceVal = (this.state.provinceList || []).find(item => item.name === value)
    } else {
      newProvinceVal = (this.state.provinceList || []).find(item => item.id === value)
    }

    if (newProvinceVal) {
      newProvinceVal = {
        id: newProvinceVal.id,
        kd_prop: newProvinceVal.kd_prop
      }
    }
    this.props.sendProvince(newProvinceVal)
  }

  handleChangeEducation = (value) => {
    this.props.sendEducation(value)
  }

  handleChangeSource = (value) => {
    let newFundsVal
    let arrFund = []

    if (value.length > 1) {
      value.forEach(id => {
        newFundsVal = (this.state.fundList || []).find(item => item.kd_sumber_dana === id)
        newFundsVal && arrFund.push({kd_sumber_dana: newFundsVal.kd_sumber_dana, fund_source_name: newFundsVal.name})
      })
    } else if (value.length === 1) {
      newFundsVal = (this.state.fundList || []).find(item => item.kd_sumber_dana === value[0])
      newFundsVal && arrFund.push({kd_sumber_dana: newFundsVal.kd_sumber_dana, fund_source_name: newFundsVal.name})
    } else {
      newFundsVal = ''
      arrFund = []
    }

    this.props.sendSource(arrFund) //send value to parent
  }

  onChangeJenjangType = (e) => {
    this.setState({ jenjangTypeValue: e })

    if (e) {
      this.props.formIdentity.current.setFieldsValue({
        education_stage: undefined,
        is_all_education_stage: true
      })
      this.props.sendEducation([]) //send empty array if jenjang all
    }
  }

  onChangeSumberDanaType = (e) => {
    this.setState({ sumberDanaTypeValue: e })

    if (e) {
      this.props.formIdentity.current.setFieldsValue({
        fund_sources: undefined,
        is_all_fund_source: true
      })
      this.props.sendSource([]) //send empty array if source all
    }
  }

  render(){
    const {
      provinceList, onLoadProvince, fundList, onLoadFunds,
      educationList, onLoadEducation, jenjangTypeValue,
      sumberDanaTypeValue,
    } = this.state

    return(
      <React.Fragment>
        <ConfigProvider renderEmpty={CustomizeNotFound}>
          <Form.Item
            label="Wilayah"
            name="province"
            rules={[{ required: true, message: "Wajib diisi" }]}
          >
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder="Pilih provinsi"
              onChange={this.handleChangeProvince}
              loading={onLoadProvince}
              disabled={onLoadProvince}
            >
              {(provinceList || []).map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
            </Select>
          </Form.Item>

          <Form.Item
            label="Jenjang Pendidikan"
            name="is_all_education_stage"
            initialValue={false}
            className={`${!jenjangTypeValue ? 'mb-2' : ''}`}
          >
            <Radio.Group onChange={(e) => this.onChangeJenjangType(e.target.value)} value={jenjangTypeValue}>
              <Radio value={false}>Pilih Sebagian</Radio>
              <Radio value={true}>Pilih Semua</Radio>
            </Radio.Group>
          </Form.Item>

          {!jenjangTypeValue &&
            <Form.Item
              {...tailFormItemLayout}
              name="education_stage"
              rules={[{ required: true, message: "Wajib diisi" }]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Pilih jenjang pendidikan"
                onChange={this.handleChangeEducation}
                loading={onLoadEducation}
                disabled={onLoadEducation}
              >
              {(educationList || []).map((item, index) => <Select.Option key={index} value={item.stage}>{item.stage}</Select.Option>)}
              </Select>
            </Form.Item>
          }

          <Form.Item
            label="Sumber Dana"
            name="is_all_fund_source"
            initialValue={false}
            className={`${!sumberDanaTypeValue ? 'mb-2' : ''}`}
          >
            <Radio.Group onChange={(e) => this.onChangeSumberDanaType(e.target.value)} value={sumberDanaTypeValue}>
              <Radio value={false}>Pilih Sebagian</Radio>
              <Radio value={true}>Pilih Semua</Radio>
            </Radio.Group>
          </Form.Item>

          {!sumberDanaTypeValue &&
            <Form.Item
              {...tailFormItemLayout}
              name="fund_sources"
              rules={[{ required: true, message: "Wajib diisi" }]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Pilih sumber dana"
                onChange={this.handleChangeSource}
                loading={onLoadFunds}
                disabled={onLoadFunds}
              >
                {(fundList || []).map((item, index) => <Select.Option key={index} value={item.kd_sumber_dana}>{item.name}</Select.Option>)}
              </Select>
            </Form.Item>
          }
        </ConfigProvider>
      </React.Fragment>
    )
  }
}

export default connect(
  ({auth}) => ({ auth }),
  { fetchProvince, fetchEducation, fetchFunds }
)(DinasProvinsi)