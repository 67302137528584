import React, {Component, createRef, Fragment} from 'react'
import { connect } from 'react-redux'
// import { withRouter } from 'react-router-dom'
import { SatuanPendidikan, DinasKabupaten, DinasProvinsi, Pegawai, PengawasKabKota, } from '../../components'
import history from '../../utils/History'
import { Container, Card, Row, Col } from 'react-bootstrap'
import { UploadOutlined, DeleteTwoTone, PaperClipOutlined } from '@ant-design/icons'
import { Form, Input, Select, Upload, Divider, Button, ConfigProvider } from 'antd'
import { updateSiteConfiguration } from '../../actions/site'
import { fetchRole } from '../../actions/Role'
import { fetchPartner } from '../../actions/Partner'
import { fetchKey } from '../../actions/Key'
import { addRegistration } from '../../actions/Registration'
import { first } from 'lodash'
import Helper from '../../utils/Helper'
import CustomizeNotFound from '../../components/CustomizeNotFound'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
class Registration extends Component {
  formRef = createRef();
  state = {
    role: [],
    isRole: null,
    isLoadRole: true,
    additionalForm: {},
    provinceVal: null,
    cityVal: null,
    educationVal: [],
    sourceVal: null,
    attached:'',
    onSubmit: false,
    dokumen: '',
    fileName: '',
    partner: [],
    isPartner: null,
    isLoadPartner: true,
    isKey: null,
    isAgencyName: null,
  }

  componentDidMount() {
    const { updateSiteConfiguration, fetchRole, fetchPartner } = this.props
    updateSiteConfiguration('activePage', 'approval-permission/create')

    fetchRole(process.env.REACT_APP_API_KEY)
    .then((result) => {
      this.setState({
        isLoadRole: false,
        role: result.items
      })
    }).catch((error) => {
      this.setState({
        isLoadRole: false,
        role: []
      })
    })

    fetchPartner(process.env.REACT_APP_API_KEY)
    .then((result) => {
      this.setState({
        isLoadPartner: false,
        partner: result.items
      })
    }).catch((error) => {
      this.setState({
        isLoadPartner: false,
        partner: []
      })
    })
  }

  handleRoleChange = (val) => {
    this.setState({
      isRole: val,
      educationVal: [],
      cityVal: null,
      provinceVal: null,
      sourceVal: null,
    })
    this.formRef.current.setFieldsValue({
      province: undefined,
      city: undefined,
      education_stage: undefined,
      fund_sources: undefined,
      is_all_education_stage: false,
      is_all_fund_source: false,
    })
  }

  handleRemoveFile = () => {
    this.setState({
      dokumen: ''
    })
  }

  handleChange = (info) => {
    const isFileValid = Helper.beforeUpload(info)
    let fileList = [...info.fileList]
    fileList = fileList.slice(-1)
    if (isFileValid && fileList.length > 0) {
      const base64 = Helper.getBase64(info.file, file =>
        this.setState({
          dokumen: file,
          fileName: first(fileList)?.name,
        })
      )
      return base64
    }
    this.setState({
      dokumen: '',
      fileName: '',
    })
    return null;
  }

  handleDeleteFile = () => {
    this.setState({
      dokumen: '',
      fileName: '',
    })
  }

  getSchoolPermission = (val) => {
    if (val) {
      this.setState({
        additionalForm: val
      })
    }
  }

  getProvinceVal = (val) => {
    this.setState({provinceVal: val})
  }

  getCityVal = (val) => {
    this.setState({cityVal: val})
  }

  getEducationVal = (val) => {
    this.setState({educationVal: val})
  }

  getSourceVal = (val) => {
    this.setState({sourceVal: val})
  }

  getPartnerVal = (val) => {
    this.setState({
      isPartner: val,
      isAgencyName: this.state.partner.filter((d)=>d.marketplace_id === val)[0].display_name
    })
  }

  handleInstanceNameChange = (e) => {
    this.setState({
      isAgencyName: e.target.value
    })
  }
  render() {
    const {
      role, isLoadRole, isRole, additionalForm, provinceVal, cityVal,
      educationVal, sourceVal, onSubmit, fileName, partner, isLoadPartner, isPartner, isAgencyName
    } = this.state;
    const onFinish = (values) => {
      this.setState({
        onSubmit: true
      })
      const {addRegistration} = this.props
      let payload
      if (isRole === 1) {         // satuan pendidikan
        payload = {
          ...values,
          nik: (values.nik).toString(),
          phone_number: (values.phone_number).toString(),
          sk_file: this.state.dokumen ||'',
          agency_name: this.state.isAgencyName,
        }
        delete payload.province
        delete payload.city
      } else if (isRole === 2) {         // satuan pendidikan
        payload = {
          ...values,
          nik: (values.nik).toString(),
          phone_number: (values.phone_number).toString(),
          sk_file: this.state.dokumen ||'',
          agency_name: this.state.isAgencyName,
          permission_request_data:{
            school_permission:{
              npsn: additionalForm.npsn,
              school_name: additionalForm.name,
              headmaster_name: additionalForm.kepsek,
              address: additionalForm.alamat_jalan,
              province_name: additionalForm?.provinsi,
              city_name: additionalForm?.kabupaten,
              subdistrict_name: additionalForm?.kecamatan,
              school_id: additionalForm?.id
            }
          }
        }
        delete payload.province
        delete payload.city
      } else if (isRole === 3) {      // dinas kabupaten/kota dan pengawas kab/prov
        payload = {
          ...values,
          nik: (values.nik).toString(),
          phone_number: (values.phone_number).toString(),
          sk_file: this.state.dokumen ||'',
          agency_name: this.state.isAgencyName,
          permission_request_data:{
            city_agency_permission:{
              province: provinceVal,
              city: cityVal,
              education_stage: educationVal,
              fund_sources: sourceVal,
              is_all_education_stage: values.is_all_education_stage,
              is_all_fund_source: values.is_all_fund_source
            }
          }
        }
        delete payload.is_all_education_stage
        delete payload.is_all_fund_source
        delete payload.province
        delete payload.city
        delete payload.education_stage
        delete payload.fund_sources
      } else if (isRole === 4) {  // dinas provinsi
        payload = {
          ...values,
          nik: (values.nik).toString(),
          phone_number: (values.phone_number).toString(),
          sk_file: this.state.dokumen ||'',
          agency_name: this.state.isAgencyName,
          permission_request_data:{
            province_agency_permission:{
              province: provinceVal,
              education_stage: educationVal,
              fund_sources: sourceVal,
              is_all_education_stage: values.is_all_education_stage,
              is_all_fund_source: values.is_all_fund_source
            }
          },
        }
        delete payload.is_all_education_stage
        delete payload.is_all_fund_source
        delete payload.province
        delete payload.education_stage
        delete payload.fund_sources
      } else if (isRole === 7) {
        payload = {         // pengawas kab/kota
          ...values,
          nik: (values.nik).toString(),
          phone_number: (values.phone_number).toString(),
          sk_file: this.state.dokumen ||'',
          agency_name: this.state.isAgencyName,
          permission_request_data:{
            city_overseer_permission:{
              province: provinceVal,
              cities: cityVal,
              education_stage: educationVal,
              fund_sources: sourceVal,
              is_all_education_stage: values.is_all_education_stage,
              is_all_fund_source: values.is_all_fund_source,
              is_all_cities: values.is_all_cities
            }
          }
        }
        delete payload.is_all_education_stage
        delete payload.is_all_fund_source
        delete payload.is_all_cities
        delete payload.province
        delete payload.education_stage
        delete payload.city
        delete payload.fund_sources
      }else if(isRole === 8){
        payload = {         // mitra SIPLah
          ...values,
          nik: (values.nik).toString(),
          phone_number: (values.phone_number).toString(),
          sk_file: this.state.dokumen ||'',
          agency_name: this.state.isAgencyName,
          permission_request_data: {
            marketplace_agency_permission: {
              marketplace: {
                marketplace_id: isPartner
              }
            }
          }
        }
        delete payload.is_all_education_stage
        delete payload.is_all_fund_source
        delete payload.is_all_cities
        delete payload.is_all_provinces
        delete payload.province
        delete payload.education_stage
        delete payload.city
        delete payload.fund_sources
      } else {
        payload = {         // pengawas pusat / provinsi, pegawai provinsi
          ...values,
          nik: (values.nik).toString(),
          phone_number: (values.phone_number).toString(),
          sk_file: this.state.dokumen ||'',
          permission_request_data:{
            centralprov_overseer_permission:{
              provinces: provinceVal,
              cities: cityVal,
              education_stage: educationVal,
              fund_sources: sourceVal,
              is_all_education_stage: values.is_all_education_stage,
              is_all_fund_source: values.is_all_fund_source,
              is_all_cities: values.is_all_cities,
              is_all_provinces: values.is_all_provinces
            }
          }
        }
        delete payload.is_all_education_stage
        delete payload.is_all_fund_source
        delete payload.is_all_cities
        delete payload.is_all_provinces
        delete payload.province
        delete payload.education_stage
        delete payload.city
        delete payload.fund_sources
      }
      addRegistration(process.env.REACT_APP_API_KEY, payload)
      .then((result) => {
        this.formRef.current.resetFields()
        this.setState({
          onSubmit: false,
          dokumen: ''
        })
        history.push('/login')
      }).catch((error) => {
        this.setState({
          onSubmit: false
        })
      })
      this.setState({
        onSubmit: false
      })
    };
    const onFinishFailed = (errorInfo) => {
      /*console.log(errorInfo)*/
    };
    return (
      <Fragment>
        <ConfigProvider renderEmpty={CustomizeNotFound}>
          <div>
            <div className="d-flex align-items-center flex-column justify-content-center h-100-vh">
              <Container>
                <Row className="justify-content-center">
                  <Col xs="12" md="8" lg="8" className="m-b-10">
                    <Card className="card-login login-pg">
                        <Card.Body>
                          <div className="mb-2">
                            <h5>Formulir Permintaan Hak Akses</h5>
                          </div>
                          <hr/>
                          <Form
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            {...formItemLayout}
                            ref={this.formRef}
                            name="formRegistration"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                          >
                            <div className="scrollable-register">
                              <Form.Item
                                name="user_name"
                                label="Nama Pengguna"
                                rules={[
                                  {
                                    required: true,
                                    message: "Wajib diisi",
                                  },
                                  {
                                    validationTrigger: 'onKeyDown',
                                    message: "Nama pengguna hanya dapat menggunakan karakter A-Z, a-z, 0-9, titik (.), dan apostrof (') serta diawali dengan karakter A-Z, a-z, dan 0-9",
                                    pattern: "^[a-zA-Z0-9][a-zA-Z0-9.' ]*$"
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                              <Form.Item
                                name="nik"
                                label="NIK/NIP"
                                rules={[
                                  {required: true, message: "Wajib diisi" },
                                  {
                                    validationTrigger: 'onKeyDown',
                                    message: "NIK/NIP hanya dapat menggunakan angka 0-9",
                                    pattern: "^[0-9]+$"
                                  },
                                ]}
                              >
                                <Input style={{ width: '100%' }}/>
                              </Form.Item>
                              <Form.Item
                                name="phone_number"
                                label="No. Telepon/HP"
                                rules={[
                                  {
                                    required: true,
                                    message: "Wajib diisi"
                                  },
                                  {
                                    validationTrigger: 'onKeyDown',
                                    message: "Nomor Telepon hanya dapat menggunakan nomor 0-9",
                                    pattern: "^[0-9]*$"
                                  },
                                  {
                                    validationTrigger: "onKeyDown",
                                    message: "Minimal 10 nomor",
                                    min: 10,
                                  },
                                  {
                                    validationTrigger: "onKeyDown",
                                    message: "Maksimal 15 nomor",
                                    max: 15,
                                  }
                                ]}
                              >
                                <Input style={{ width: '100%' }}/>
                              </Form.Item>
                              <Form.Item
                                name="email"
                                label="Email Pengguna"
                                rules={[
                                  {
                                    required: true,
                                    message: "Wajib diisi"
                                  },
                                  {
                                    validationTrigger: "onKeyDown",
                                    type: 'email',
                                    message: "Harus menggunakan format email yang valid"
                                  }
                                ]}
                              >
                                <Input />
                              </Form.Item>
                              <Form.Item
                                name="position"
                                label="Jabatan"
                                rules={[{ required: true, message: "Wajib diisi" }]}
                              >
                                <Input />
                              </Form.Item>
                              {isRole !== 8 ?
                                <Form.Item
                                  name="agency_name"
                                  label="Nama instansi"
                                  rules={[{ required: isRole === 8 ? false : true, message: isRole === 8 ? null : "Wajib diisi" }]}
                                >
                                  <Input onChange={this.handleInstanceNameChange} value={isAgencyName} />
                                </Form.Item>
                              :
                                <Form.Item
                                  name="agency_name"
                                  label="Nama instansi"
                                  rules={[{ required: isRole === 8 ? false : true, message: isRole === 8 ? null : "Wajib diisi" }]}
                                >
                                  {isAgencyName || '-'}
                                </Form.Item>
                              }
                              <Form.Item
                                name="role"
                                label="Jenis Instansi"
                                rules={[{ required: true, message: "Wajib diisi" }]}
                              >
                                <Select
                                  loading={isLoadRole}
                                  disabled={isLoadRole}
                                  onChange={this.handleRoleChange}
                                >
                                  {(role || []).map(item => <Select.Option key={item.id} value={item.id}>{item.role_display_name}</Select.Option>)}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                name="sk_file"
                                label="Unggah"
                                valuePropName="file"
                                getValueFromEvent={this.handleChange}
                                extra="Berkas dapat berupa Nota Dinas (bagi Kementerian) atau Surat Penunjukan (bagi Mitra SIPLah). Format berkas yang dapat diunggah adalah PDF, PNG, JPEG dengan ukuran maksimal 3,5 MB"
                                className={fileName ? 'mb-0' : ''}
                                rules={[{ required: true, message: "Silakan unggah berkas" }]}
                              >
                                <Upload
                                  beforeUpload={() => false}
                                  onRemove={() => this.handleRemoveFile}
                                  accept=".pdf,.png,.jpeg"
                                  maxCount={1}
                                  showUploadList={false}
                                >
                                  <Button className="mb-2" icon={<UploadOutlined />}>Klik untuk Unggah</Button>
                                </Upload>
                              </Form.Item>
                              {fileName &&
                                <Form.Item {...tailFormItemLayout}>
                                  <div className="d-flex justify-content-between align-items-center" style={{backgroundColor: '#e0f3ff7a'}}>
                                    <div className="d-flex align-items-center ml-2">
                                      <PaperClipOutlined className="mr-2" />
                                      <span className="d-block">{fileName || ''}</span>
                                    </div>
                                    <DeleteTwoTone twoToneColor="#ff4d4f" onClick={this.handleDeleteFile} className="cursor-pointer mr-2" />
                                  </div>
                                </Form.Item>
                              }
                              {isRole === 2 &&
                                <React.Fragment>
                                  <Row>
                                    <Col sm={12}>
                                      <Divider orientation="left" plain>Permintaan Hak Akses</Divider>
                                    </Col>
                                  </Row>
                                  <SatuanPendidikan
                                    passValue={this.getSchoolPermission}
                                    sendProvince={this.getProvinceVal}
                                    sendCity={this.getCityVal}
                                  />
                                </React.Fragment>
                              }

                              {isRole === 3 &&
                                <React.Fragment>
                                  <Row>
                                    <Col sm={12}>
                                      <Divider orientation="left" plain>Permintaan Hak Akses</Divider>
                                    </Col>
                                  </Row>
                                  <DinasKabupaten
                                    sendProvince={this.getProvinceVal}
                                    sendCity={this.getCityVal}
                                    sendEducation={this.getEducationVal}
                                    sendSource={this.getSourceVal}
                                    formIdentity={this.formRef}
                                  />
                                </React.Fragment>
                              }

                              {isRole === 4 &&
                                <React.Fragment>
                                  <Row>
                                    <Col sm={12}>
                                      <Divider orientation="left" plain>Permintaan Hak Akses</Divider>
                                    </Col>
                                  </Row>
                                  <DinasProvinsi
                                    sendProvince={this.getProvinceVal}
                                    sendEducation={this.getEducationVal}
                                    sendSource={this.getSourceVal}
                                    formIdentity={this.formRef}
                                  />
                                </React.Fragment>
                              }

                              {(isRole === 5 || isRole === 6) &&
                                <React.Fragment>
                                  <Row>
                                    <Col sm={12}>
                                      <Divider orientation="left" plain>Permintaan Hak Akses</Divider>
                                    </Col>
                                  </Row>
                                  <Pegawai
                                    sendProvince={this.getProvinceVal}
                                    sendCity={this.getCityVal}
                                    sendEducation={this.getEducationVal}
                                    sendSource={this.getSourceVal}
                                    formIdentity={this.formRef}
                                  />
                                </React.Fragment>
                              }

                              {isRole === 7 &&
                                <React.Fragment>
                                  <Row>
                                    <Col sm={12}>
                                      <Divider orientation="left" plain>Permintaan Hak Akses</Divider>
                                    </Col>
                                  </Row>
                                  <PengawasKabKota
                                    sendProvince={this.getProvinceVal}
                                    sendCity={this.getCityVal}
                                    sendEducation={this.getEducationVal}
                                    sendSource={this.getSourceVal}
                                    formIdentity={this.formRef}
                                  />
                                </React.Fragment>
                              }

                              {isRole === 8 &&
                                <React.Fragment>
                                  <Row>
                                    <Col sm={12}>
                                      <Divider orientation="left" plain>Permintaan Hak Akses</Divider>
                                    </Col>
                                  </Row>
                                  <Form.Item
                                    name="partner"
                                    label="Mitra SIPLah"
                                    rules={[{ required: true, message: "Silakan pilih Mitra SIPLah" }]}
                                  >
                                    <Select
                                      loading={isLoadPartner}
                                      disabled={isLoadPartner}
                                      onChange={this.getPartnerVal}
                                    >
                                      {(partner || []).map(item => <Select.Option key={item.marketplace_id} value={item.marketplace_id}>{item.display_name}</Select.Option>)}
                                    </Select>
                                  </Form.Item>
                                </React.Fragment>
                              }

                              {isRole === null &&
                                <React.Fragment/>
                              }

                            </div>
                            <Row className="p-b-10 p-t-10 p-l-10 p-r-10">
                              <Col sm={12}>
                                <div className="d-flex justify-content-end">
                                  <Button type="default" href="/login" className="m-r-10">
                                    Kembali ke halaman Login
                                  </Button>
                                  <Button type="primary" htmlType="submit" loading={onSubmit}>
                                    Kirim
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </ConfigProvider>
      </Fragment>
    )
  }
}
export default connect(
  () => ({}),
  {addRegistration, fetchRole, updateSiteConfiguration, fetchPartner, fetchKey}
)(Registration)
